export const MASTER_REQUEST = 'MASTER_REQUEST';
export const MASTER_SUCCESS = 'MASTER_SUCCESS';
export const MASTER_FAILD = 'MASTER_FAILD';

export const LABELIST_REQUEST = 'LABELIST_REQUEST';
export const LABELIST_SUCCESS = 'LABELIST_SUCCESS';
export const LABELIST_FAILD = 'LABELIST_FAILD';

export const LANGUAGElIST_REQUEST = 'LANGUAGElIST_REQUEST';
export const LANGUAGElIST_SUCCESS = 'LANGUAGElIST_SUCCESS';
export const LANGUAGElIST_FAILD = 'LANGUAGElIST_FAILD';


// add to cart
export const CART_REQUEST = 'CART_REQUEST';
export const CART_SUCCESS = 'CART_SUCCESS';
export const CART_FAILD = 'CART_FAILD';

export const MY_CART_REQUEST = 'MY_CART_REQUEST';
export const MY_CART_SUCCESS = 'MY_CART_SUCCESS';
export const MY_CART_FAILD = 'MY_CART_FAILD';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const WISH_REQUEST = 'WISH_REQUEST';
export const WISH_SUCCESS = 'WISH_SUCCESS';
export const WISH_FAILD = 'WISH_FAILD';

export const KEEP_IN_TOUCH_REQUEST = 'KEEP_IN_TOUCH_REQUEST';
export const KEEP_IN_TOUCH_SUCCESS = 'KEEP_IN_TOUCH_SUCCESS';
export const KEEP_IN_TOUCH_FAILD = 'KEEP_IN_TOUCH_FAILD';
export const KEEP_IN_TOUCH_CLEAR = 'KEEP_IN_TOUCH_CLEAR';

