import { PRODUCT_FILTER_REQUEST, PRODUCT_FILTER_SUCCESS, PRODUCT_FILTER_FAILD } from './productTypes';
import { PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAILD } from './productTypes';
export function productFilterReducer(state = [], action) {
    switch (action.type) {
        case PRODUCT_FILTER_SUCCESS:
            return { ...action.payload };
        case PRODUCT_FILTER_FAILD:
            return { ...action.payload };
        case PRODUCT_FILTER_REQUEST:
            return { ...action.payload };
        default:
            return state;
    }
}
export function productListReducer(state = [], action) {
    switch (action.type) {
        case PRODUCT_LIST_SUCCESS:
            return { ...action.payload };
        case PRODUCT_LIST_FAILD:
            return { ...action.payload };
        case PRODUCT_LIST_REQUEST:
            return { ...action.payload };
        default:
            return state;
    }
}