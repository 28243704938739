import { HOME_REQUEST, HOME_SUCCESS, HOME_FAILD, YOU_SCREEN_REQUEST, YOU_SCREEN_SUCCESS, YOU_SCREEN_FAILD } from './homeTypes';
import { HOME_DYNAMIC_REQUEST,HOME_DYNAMIC_SUCCESS,HOME_DYNAMIC_FAILD,
    MEGA_MENU_REQUEST, MEGA_MENU_SUCCESS, MEGA_MENU_FAILD } from './homeTypes';
import { SHOP_BY_PET_REQUEST,SHOP_BY_PET_SUCCESS,SHOP_BY_PET_FAILD } from './homeTypes';
export function homeReducer(state = [], action) {
    switch (action.type) {
        case HOME_SUCCESS:
            return { ...action.payload };
        case HOME_FAILD:
            return { ...action.payload };
        case HOME_REQUEST:
            return { ...action.payload };
        default:
            return state;
    }
}

export function youScreenReducer(state = [], action) {
    switch (action.type) {
        case YOU_SCREEN_REQUEST:
            return { ...action.payload };
        case YOU_SCREEN_SUCCESS:
            return { ...action.payload };
        case YOU_SCREEN_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}


export  function homeDynamicReducer(state = [], action) {
    switch (action.type) {
        case HOME_DYNAMIC_SUCCESS:
            return { ...action.payload };
        case HOME_DYNAMIC_FAILD:
            return { ...action.payload};
        case HOME_DYNAMIC_REQUEST:
            return { ...action.payload };
        default:
            return state;
    }
} 

export  function shopByPetReducer(state = [], action) {
    switch (action.type) {
        case SHOP_BY_PET_REQUEST:
            return { ...action.payload };
        case SHOP_BY_PET_FAILD:
            return { ...action.payload};
        case SHOP_BY_PET_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
}

export function megaMenuReducer(state = [], action) {
    switch (action.type) {
        case MEGA_MENU_REQUEST:
            return { ...action.payload };
        case MEGA_MENU_SUCCESS:
            return { ...action.payload };
        case MEGA_MENU_FAILD:
            return { ...action.payload };
        default:
            return state;

    }
}