import animationData from "assets/lotties/lf30_editor_no9y5qo2.json";
import React, { Component } from "react";
import Lottie from "react-lottie";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

export class Loader extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: true,
  };

  renderMain() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      speed: 1,
      animationData: animationData,
      background: "transparent",
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      // {isPaused :true }   controls play push
      <div>
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
    );
  }

  render() {
    const { isLoading } = this.props;

    if (!isLoading) {
      return null;
    }

    return (
      <Container>
        <div className="Loader">{this.renderMain()}</div>
      </Container>
    );
  }
}
