module.exports = {
  baseURL: "",
  appversion: "1.0",
  deviceOSType: "web",
  deviceType: "M",
  langCode: "default",
  loginType: "0",
  googleApiKey: "",
  apiEndpointsObj: {
    getLabelList: "",
    homePage: "",
    getLanguageList: "",
    productList: "",
    search: "",
    brandList: "",
    filterList: "",
    storesList: "",
    influencerList: "",
    productDetails: "",
    getMegaMenu: "",
    login: "",
    signUp: "",
    addToWishlist: "",
    addtocart: "",
    applyFilter: "",
    forgetPassword: "",
    getCheckoutList: "",
    getMyWishlistProducts: "",
    logout: "",
    master: "",
    myCart: "",
    productDescription: "",
    resetPassword: "",
    storeDetail: "",
    youScreen: "",
    getShopByPet: "",
    newsletterSubscription: '',
  },
  defaultParameters: {
    customerId: "",
    websiteId: "",
    groupId: "",
    storeId: "",
  },
  isUiFlip: "0",
  countryList: [],
  languageData: [],
  totalCartItem: "0"
};
