import * as authType from "./authTypes";

const data = JSON.parse(localStorage.getItem("user"));
const initialState = data ? { isLoggedIn: true, data } : { isLoggedIn: false, data: null };

export function authReducer(state = initialState, action) {
  // console.log("action.type:", action.type);
  // console.log("state:", state);
  switch (action.type) {
    case authType.CHECK_AUTHORIZATION_REQUEST:
      return { ...action.payload };

    case authType.CHECK_AUTHORIZATION_SUCCESS:
      state.isLoggedIn = true;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.CHECK_AUTHORIZATION_FAILD:
      state.isLoggedIn = false;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.LOGIN_REQUEST:
      state.isLoggedIn = false;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.LOGIN_SUCCESS:
      state.isLoggedIn = true;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.LOGIN_FAILD:
      state.isLoggedIn = false;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.LOGIN_CLEAR:
      action.payload.type = 'LOGIN_CLEAR';
      return { ...action.payload };

    case authType.LOGOUT_REQUEST:
      return { ...action.payload };

    case authType.LOGOUT_SUCCESS:
      state.isLoggedIn = false;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.LOGOUT_FAILD:
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.CREATE_ACCOUNT_REQUEST:
      return { ...action.payload };

    case authType.CREATE_ACCOUNT_SUCCESS:
      state.isLoggedIn = true;
      action.payload["isLoggedIn"] = state.isLoggedIn;
      return { ...action.payload };

    case authType.CREATE_ACCOUNT_FAILD:
      return { ...action.payload };

    default:
      return state;
  }
}

export function userPasswordReducer(state = {}, action) {
  switch (action.type) {
    case authType.RESET_PASSWORD_REQUEST:
      return { ...action.payload };

    case authType.RESET_PASSWORD_SUCCESS:
      return { ...action.payload };

    case authType.RESET_PASSWORD_FAILD:
      return { ...action.payload };

    case authType.FORGOT_PASSWORD_REQUEST:
      return { ...action.payload };

    case authType.FORGOT_PASSWORD_SUCCESS:
      return { ...action.payload };

    case authType.FORGOT_PASSWORD_FAILD:
      return { ...action.payload };

    default:
      return state;
  }
}


