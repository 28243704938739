import { BRANDS_REQUEST, BRANDS_SUCCESS, BRANDS_FAILD } from './storeTypes';
import { PRODUCT_FILTER_REQUEST, PRODUCT_FILTER_SUCCESS, PRODUCT_FILTER_FAILD } from './storeTypes';
import { APPLY_FILTER_REQUEST, APPLY_FILTER_SUCCESS, APPLY_FILTER_FAILD } from './storeTypes';
import { INFLUNCER_LIST_REQUEST, INFLUNCER_LIST_SUCCESS, INFLUNCER_LIST_FAILD } from './storeTypes';
import { STORE_DETAIL_REQUEST, STORE_DETAIL_SUCCESS, STORE_DETAIL_FAILD } from './storeTypes';
import { STORE_LIST_REQUEST, STORE_LIST_SUCCESS, STORE_LIST_FAILD } from './storeTypes';

export function brandsReducer(state = [], action) {
    switch (action.type) {
        case BRANDS_SUCCESS:
            return { ...action.payload };
        case BRANDS_FAILD:
            return { ...action.payload };
        case BRANDS_REQUEST:
            return { ...action.payload };
        default:
            return state;
    }
}

export function applyFilterReducer(state = [], action) {
    switch (action.type) {
        case APPLY_FILTER_SUCCESS:
            return { ...action.payload };
        case APPLY_FILTER_FAILD:
            return { ...action.payload };
        case APPLY_FILTER_REQUEST:
            return { ...action.payload };
        default:
            return state;
    }
}

export function influencerList(state = [], action) {
    switch (action.type) {
        case INFLUNCER_LIST_REQUEST:
            return { ...action.payload };
        case INFLUNCER_LIST_SUCCESS:
            return { ...action.payload };
        case INFLUNCER_LIST_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}

export function storeDetailReducer(state = [], action) {
    switch (action.type) {
        case STORE_DETAIL_REQUEST:
            return { ...action.payload };
        case STORE_DETAIL_SUCCESS:
            return { ...action.payload };
        case STORE_DETAIL_FAILD:
        default:
            return state;
    }
}
export function storeList(state = [], action) {
    switch (action.type) {
        case STORE_LIST_REQUEST:
            return { ...action.payload };
        case STORE_LIST_SUCCESS:
            return { ...action.payload };
        case STORE_LIST_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}

