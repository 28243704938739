export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILD = "LOGIN_FAILD";
export const LOGIN_CLEAR = "LOGIN_CLEAR";

export const CHECK_AUTHORIZATION_REQUEST = "CHECK_AUTHORIZATION_REQUEST";
export const CHECK_AUTHORIZATION_SUCCESS = "CHECK_AUTHORIZATION_SUCCESS";
export const CHECK_AUTHORIZATION_FAILD = "CHECK_AUTHORIZATION_FAILD";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILD = "LOGOUT_FAILD";

export const CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT_REQUEST";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILD = "CREATE_ACCOUNT_FAILD";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILD = "FORGOT_PASSWORD_FAILD";


export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILD = "RESET_PASSWORD_FAILD";

