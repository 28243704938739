import { combineReducers } from 'redux';
import { homeReducer, youScreenReducer, homeDynamicReducer, shopByPetReducer, megaMenuReducer } from 'RootRedux/HomeRedux/homeReducer';
import prodctReducer from 'RootRedux/ProductFilterRedux/productReducer';
import { masterReducer, labelListReducer, languageListReducer, addToCart, loader, addToWishReducer, keepInTouch } from "RootRedux/CommonRedux/commonReducer";
import { productFilterReducer, productListReducer } from 'RootRedux/ProductFilterRedux/productReducer';
import { authReducer, userPasswordReducer } from './AuthRedux/authReducer';
import { applyFilterReducer, influencerList, storeDetailReducer, storeList, brandsReducer } from './StoreRedux/storeReducer';

// const appReducer = combineReducers({
//     // form: formReducer,
//     responceJson : homeReducer
// });

// const rootReducer = (data, type) => {
//     return appReducer(data, type)
// }

export default combineReducers({
    responseData: homeReducer,
    applyFilterList: applyFilterReducer,
    youScreenData: youScreenReducer,
    authData: authReducer,
    userPasswordData: userPasswordReducer,
    masterData: masterReducer,
    labelListData: labelListReducer,
    languageData: languageListReducer,
    productFilterList: productFilterReducer,
    productListData: productListReducer,
    influencerList: influencerList,
    shopByPetData: shopByPetReducer,
    megaMenuData: megaMenuReducer,
    storeDetailData: storeDetailReducer,
    storeList: storeList,
    homeData: homeDynamicReducer,
    addToCartData: addToCart,
    brandsData: brandsReducer,
    loader: loader,
    addToWishData: addToWishReducer,
    keepInTouch: keepInTouch
});

//     responseData: storeReducer,ƒƒ
//     authData: authReducer
// });

//export default rootReducer;