export const BRANDS_REQUEST = 'BRANDS_REQUEST';
export const BRANDS_SUCCESS = 'BRANDS_SUCCESS';
export const BRANDS_FAILD = 'BRANDS_FAILD';

export const APPLY_FILTER_REQUEST = 'APPLY_FILTER_REQUEST';
export const APPLY_FILTER_SUCCESS = 'APPLY_FILTER_SUCCESS';
export const APPLY_FILTER_FAILD = 'APPLY_FILTER_FAILD';

export const INFLUNCER_LIST_REQUEST = 'INFLUNCER_LIST_REQUEST';
export const INFLUNCER_LIST_SUCCESS = 'INFLUNCER_LIST_SUCCESS';
export const INFLUNCER_LIST_FAILD = 'INFLUNCER_LIST_FAILD';

export const STORE_DETAIL_REQUEST = 'STORE_DETAIL_REQUEST';
export const STORE_DETAIL_SUCCESS = 'STORE_DETAIL_SUCCESS';
export const STORE_DETAIL_FAILD = 'STORE_DETAIL_FAILD';

export const STORE_LIST_REQUEST = 'STORE_LIST_REQUEST';
export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
export const STORE_LIST_FAILD = 'STORE_LIST_FAILD';


