const constant = require("./Constant/globleSettings");
let isUiFlip = JSON.parse(localStorage.getItem('isUiFlip'));
if (!isUiFlip) {
  localStorage.setItem('isUiFlip',JSON.stringify(constant.isUiFlip));
  isUiFlip = constant.isUiFlip;
}
const direction = isUiFlip == "1" ? "rtl" : "ltr"

let render = () => {
  import('./Styles/direction/' + direction + '.scss').then(x => {
    require('./AppRenderer');
  });
};
render();