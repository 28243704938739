import {
    MASTER_REQUEST, MASTER_SUCCESS, MASTER_FAILD, LABELIST_REQUEST, LABELIST_SUCCESS, LABELIST_FAILD, LANGUAGElIST_REQUEST, LANGUAGElIST_SUCCESS, LANGUAGElIST_FAILD,
    CART_REQUEST, CART_SUCCESS, CART_FAILD,
    SHOW_LOADER, HIDE_LOADER,
    WISH_REQUEST, WISH_SUCCESS, WISH_FAILD,
    KEEP_IN_TOUCH_REQUEST, KEEP_IN_TOUCH_SUCCESS, KEEP_IN_TOUCH_FAILD
} from './commonTypes';
let cartResponse = {
    cartCount: "0",
    message: "Product Added To Cart.",
    quoteId: "0",
    status: "SUCCESS",
    statusCode: "200"
}

export function masterReducer(state = [], action) {
    switch (action.type) {
        case MASTER_REQUEST:
            return { ...action.payload };
        case MASTER_SUCCESS:
            return { ...action.payload };
        case MASTER_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}


export function labelListReducer(state = [], action) {
    switch (action.type) {
        case LABELIST_REQUEST:
            return { ...action.payload };
        case LABELIST_SUCCESS:
            return { ...action.payload };
        case LABELIST_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}

export function languageListReducer(state = [], action) {
    switch (action.type) {
        case LANGUAGElIST_REQUEST:
            return { ...action.payload };
        case LANGUAGElIST_SUCCESS:
            return { ...action.payload };
        case LANGUAGElIST_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}

export function addToCart(state = [], action) {
    switch (action.type) {
        case CART_REQUEST:
            return { ...action.payload };
        case CART_SUCCESS:
            return { ...action.payload };
        case CART_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}

export function loader(state = false, action) {
    switch (action.type) {
        case SHOW_LOADER:
            return action.data;
        case HIDE_LOADER:
            return action.data;
        default:
            return state;
    }
}

export function addToWishReducer(state = [], action) {
    switch (action.type) {
        case WISH_REQUEST:
            return { ...action.payload };
        case WISH_SUCCESS:
            return { ...action.payload };
        case WISH_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}

export function keepInTouch(state = [], action) {
    switch (action.type) {
        case KEEP_IN_TOUCH_REQUEST:
            return { ...action.payload };
        case KEEP_IN_TOUCH_SUCCESS:
            return { ...action.payload };
        case KEEP_IN_TOUCH_FAILD:
            return { ...action.payload };
        default:
            return state;
    }
}
