// export const API_DummyJson = "http://103.83.81.3/jaydeep/prtzone/API/Home.json";
export const API_DummyJson = "https://petzone.magneto.co.in/pub/staticapi/HomePage.json";
export const API_Master = "https://petzone.magneto.co.in/rest/V1/ecomapi/master";
export const API_getStoreDetails = "https://petzone.magneto.co.in/pub/staticapi/getStoreDetails.json"
export const API_youscreen = "https://petzone.magneto.co.in/pub/staticapi/youscreen.json";
export const API_MegaMenu = "https://petzone.magneto.co.in/pub/staticapi/getMegaMenu.json";
export const API_getShopByPet = "https://petzone.magneto.co.in/pub/staticapi/getShopByPet.json"
export const API_myCart = "https://petzone.magneto.co.in/pub/staticapi/mycart.json";
export const API_getFaqList = "https://petzone.magneto.co.in/pub/staticapi/getFaqList.json";
export const API_getRewardPolicy = "https://petzone.magneto.co.in/pub/staticapi/getRewardPolicy.json";
export const API_Serach = "https://petzone.magneto.co.in/pub/staticapi/getSearchList.json";
export const API_getMyPoints = " https://petzone.magneto.co.in/pub/staticapi/getMyPoints.json";
export const API_buyGiftCards = "https://petzone.magneto.co.in/pub/staticapi/buyGiftCards.json";
export const API_getOurServices = "https://petzone.magneto.co.in/pub/staticapi/getOurServices.json";
// export const API_Serach = "https://petzone.magneto.co.in/pub/staticapi/getSearchList.json";
// export const API_StoreLIst = " https://petzone.magneto.co.in/rest/default/V1/ecomapi/storesList";
export const API_getGiftCardDetails = "https://petzone.magneto.co.in/pub/staticapi/getGiftCardDetails.json"
export const API_brandList = "https://petzone.magneto.co.in/pub/staticapi/getBrandList.json"
// export const API_filterList = "https://petzone.magneto.co.in/rest/default/V1/ecomapi/filterList"
export const API_applyFilter = "https://petzone.magneto.co.in/rest/default/V1/ecomapi/applyFilter"
// export const API_productListFilter = "https://petzone.magneto.co.in/rest/default/V1/ecomapi/productList"


