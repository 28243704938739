import Direction from "Components/Direction/Direction";
import axios from "axios";
import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
// import store from "RootRedux/store";
import { store, persistor } from './RootRedux/store';
import { API_buyGiftCards, API_DummyJson, API_getFaqList, API_getGiftCardDetails, API_getMyPoints, API_getOurServices, API_getRewardPolicy, API_getShopByPet, API_getStoreDetails, API_Master, API_MegaMenu, API_myCart, API_Serach, API_youscreen } from './Constant/config.service.js';
import reportWebVitals from "./reportWebVitals";
import { Loader } from "Components/Loader/Loader.component";
import "./index.scss"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const globleSettings = require("Constant/globleSettings.js");
const App = React.lazy(() => import('./App' ));
export function getTokan() {
  return localStorage.getItem("authToken");
}

// For GET requests `

axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    if (
      req.url !== API_Master &&
      req.url !== API_DummyJson &&
      req.url !== API_getStoreDetails &&
      req.url !== API_youscreen &&
      req.url !== API_MegaMenu &&
      req.url !== API_getShopByPet &&
      req.url !== API_myCart &&
      req.url !== API_getFaqList &&
      req.url !== API_Serach &&
      req.url !== API_getRewardPolicy &&
      req.url !== API_getMyPoints &&
      req.url !== API_buyGiftCards &&
      req.url !== API_getOurServices &&
      req.url !== API_Serach &&
      req.url !== API_getGiftCardDetails
    ) {
      req.headers = { Authorization: getTokan() };
      if (req.method !== "get") {
        req.data["defaultParameters"] = globleSettings.defaultParameters;

      }
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

console.log("=======================================\n", store.getState(), '==============================================\n')

ReactDOM.render(
  // <Direction>
    <Provider store={store}>
      <Suspense fallback={<Loader isLoading={true} />}>
      <App />
      </Suspense>
    </Provider>,
  // </Direction>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
