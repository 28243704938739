export const HOME_REQUEST = 'HOME_REQUEST';
export const HOME_SUCCESS = 'HOME_SUCCESS';
export const HOME_FAILD = 'HOME_FAILD';

export const YOU_SCREEN_REQUEST = 'YOU_SCREEN_REQUEST';
export const YOU_SCREEN_SUCCESS = 'YOU_SCREEN_SUCCESS';
export const YOU_SCREEN_FAILD = 'YOU_SCREEN_FAILD';

export const HOME_DYNAMIC_REQUEST = 'HOME_DYNAMIC_REQUEST';
export const HOME_DYNAMIC_SUCCESS = 'HOME_DYNAMIC_SUCCESS';
export const HOME_DYNAMIC_FAILD = 'HOME_DYNAMIC_FAILD';


export const SHOP_BY_PET_REQUEST = 'SHOP_BY_PET_REQUEST';
export const SHOP_BY_PET_SUCCESS = 'SHOP_BY_PET_SUCCESS';
export const SHOP_BY_PET_FAILD = 'SHOP_BY_PET_FAILD';

export const MEGA_MENU_REQUEST = 'MEGA_MENU_REQUEST';
export const MEGA_MENU_SUCCESS = 'MEGA_MENU_SUCCESS';
export const MEGA_MENU_FAILD = 'MEGA_MENU_FAILD';

