import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import MemoryStorage from 'redux-persist-memory-storage';
import ReduxThunk from 'redux-thunk';

import rootReducer from './reducerIndex';
const middleware = applyMiddleware(ReduxThunk);

const persistConfig = {
    key: 'root',
    storage: new MemoryStorage()
};
const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, compose(middleware));
console.log("createStore store ", store);
export const persistor = persistStore(store);
console.log("createStore persistor ", persistor);